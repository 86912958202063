<template>
  <TheContent
    title=" All Numbers"
    :countTotalItems="countTotalItems"
    :countItems="countItems"
    :countSelectedItems="countSelectedItems"
    :activePage.sync="page"
    :pages="countPages"
    :pageSize.sync="pageSize"
  >
    <TheTotal slot="header-content" name="numbers"/>

    <!-- <pre>{{data}}</pre> -->
    <CDataTable hover striped sorter :loading="loading" :items="data" :fields="fields" index-column>
      <template #check="data">
        <td>
          <CInputCheckbox
            :checked="checkValue(data.item.id)"
            @update:checked="(id) => handlerChose(data.item.id)"
          />
        </td>
      </template>

      <template #user="{item}">
        <td>
          <span>{{item.user.email}}</span>
        </td>
      </template>

      <template #amount="{item}">
        <td>
          <span
            :style="{color: item.amount > 0 ? 'green' : 'red'}"
          >{{`${item.amount > 0 ? "+" : ""}${item.amount}`}}</span>
        </td>
      </template>
    </CDataTable>
  </TheContent>
</template>

<script>
import TheContent from "@/containers/TheContent";

import TheTotal from "@/containers/TheTotal";

import MixinsScreen from "@/mixins/screen";

export default {
  name: "numbers",

  mixins: [MixinsScreen],

  components: {
    TheContent,

    TheTotal
  },

  data: () => {
    return {
      apiName: "users/numbers/transactions",

      fields: [
        // { key: "id", label: "Id" },
        { key: "user", label: "Email" },
        { key: "country", label: "Country" },
        { key: "number", label: "Number" },
        { key: "amount", label: "Amount" },
        { key: "created_at", label: "Created" }
      ],

      filters: {
        dataDefault: {
          id: null,
          order: null,
          user: []
        },

        items: {
          id: {
            type: "select",
            label: "Select id"
          },

          user: {
            type: "complete",
            label: "Select users",
            apiName: "users",
            size: "3"
          },
          order: {
            type: "select",
            label: "Select orders",
            size: "3"
          }
        }
      }
    };
  },

  methods: {
    getBadge(status) {
      return status === "completed" ? "success" : "danger";
    },

    userLink(id) {
      return `users/${id.toString()}`;
    }
  }
};
</script>
 